// src/Components/RideCard.jsx
import React from "react";
import img from "../Asset/profile.png"

function RideCard({ rideData, onSelect }) {
  return (
    <div
      className="bg-gray-100 h-auto shadow-md rounded-lg p-4 w-full max-w-sm mb-4 cursor-pointer hover:bg-gray-200"
      onClick={() => onSelect(rideData)}
    >
      <div className="flex justify-between items-start">
        {/* Ride ID and Date */}
        <div>
          {/* <h2 className="text-md font-semibold text-gray-800">Ride ID: {rideData?.rideId}</h2> */}
          {/* <p className="text-xs text-gray-500">{new Date(rideData?.rideDate).toLocaleString()}</p> */}
        </div>
        {/* Badge */}
        <div className={`text-xs rounded-full px-2 py-1 ${getBadgeStyle(rideData?.status)}`}>
          {rideData?.badge?.toUpperCase()}
        </div>
      </div>

      {/* Customer Info */}
      <div className="flex items-center mt-3">
        <img
          src={img} 
          alt="customer"
          className="w-8 h-8 rounded-full mr-2"
        />
        <div>
          <p className="text-gray-800 text-sm font-medium">
            {rideData?.customer?.firstName + " " + rideData?.customer?.lastName}{" "} ({rideData?.customerId})
          </p>
        </div>
      </div>

      {/* Pickup Location */}
      <div className="flex items-center mt-2">
        <span className="text-red-500 mr-1">📍</span>
        <p className="text-gray-700 text-xs">{rideData?.pickupPoint ||rideData?.pickup}</p>
      </div>

      {/* Dropoff Location */}
      <div className="flex items-center mt-1">
        <span className="text-green-500 mr-1">📍</span>
        <p className="text-gray-700 text-xs">{rideData?.destinationPoint || rideData?.drop}</p>
      </div>

      {/* Driver Info */}
      <div className="flex items-center mt-3">
        <img
          src={img} // Provide a default image if none
          alt="driver"
          className="w-8 h-8 rounded-full mr-2"
        />
        <div>
          <p className="text-gray-800 text-sm font-medium">
          {rideData?.driver?.firstName + " " + rideData?.driver?.lastName}{" "} ({rideData?.driverID})
          </p>
        </div>
      </div>

      {/* Status and Price */}
      <div className="flex justify-between items-center mt-4">
        <p className="text-gray-600 text-xs">{rideData?.status}</p>
        <p className="text-gray-900 text-sm font-semibold">{rideData?.price}</p>
      </div>
    </div>
  );
}

// Helper function to determine badge style based on status
const getBadgeStyle = (status) => {
  switch (status?.toLowerCase()) {
    case 'completed':
      return 'bg-green-500 text-white';
    case 'cancelled':
      return 'bg-red-500 text-white';
    case 'accepted':
    case 'started':
    case 'reached location':
      return 'bg-yellow-500 text-white';
    default:
      return 'bg-gray-500 text-white';
  }
};

export default RideCard;
