import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import api from "../../utils/API/customerApis";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsEdit } from "../../State/userSlice";
import { Toaster, toast } from 'sonner';

function AddEditCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.user || {});
  const editElement = usersState.editElement;
  const isEdit = usersState.isEdit;

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    status: "enabled"
  });

  const [errors, setErrors] = useState({});

  // useEffect to populate form data in edit mode
  useEffect(() => {
    if (isEdit && editElement) {
      setUserDetails(editElement);
    }
  }, [isEdit, editElement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!userDetails.firstName.trim()) {
      newErrors.firstName = "First name is required.";
      toast.error("First name is required.");
    } else if (!/^[A-Za-z\s]+$/.test(userDetails.firstName)) {
      newErrors.firstName = "First name should contain only letters.";
      toast.error("First name should contain only letters.");
    }else if (userDetails.lastName.length > 20) {
      newErrors.firstName = "firstName cannot exceed 20 characters.";
      toast.error("firstName cannot exceed 20 characters.");
    }
    
    if (!userDetails.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
      toast.error("Last name is required.");
    } else if (!/^[A-Za-z\s]+$/.test(userDetails.lastName)) {
      newErrors.lastName = "Last name should contain only letters.";
      toast.error("Last name should contain only letters.");
    } else if (userDetails.lastName.length > 20) {
      newErrors.lastName = "Last name cannot exceed 20 characters.";
      toast.error("Last name cannot exceed 20 characters.");
    }
    
    if (!userDetails.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
      toast.error("Phone number is required.");
    }
    if (!userDetails.email.trim()) {
      newErrors.email = "Email is required.";
      toast.error("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      newErrors.email = "Invalid email format.";
      toast.error("Invalid email format.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
  
    try {
      let response;
      if (isEdit) {
        response = await api.updateCustomerAPI(userDetails, userDetails.id);
      } else {
        response = await api.addCustomerAPI(userDetails);
      }
  
      if (response.status === 200 || response.status === 201) {
        toast.success(isEdit ? "Customer updated successfully!" : "Customer added successfully!");
        dispatch(setIsEdit(false));
        navigate("/customer");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        // Show API error message
        toast.error(error.response.data.message);
      } else {
        // Fallback error message
        toast.error("An error occurred while submitting the form.");
      }
      console.error("Error submitting the form:", error);
    }
  };

  const handleReset = () => {
    setUserDetails({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      status: "enabled"
    });
    setErrors({});
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "customer" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <Toaster />
              <div className="flex justify-between">
                <div className="text-xl py-2 my-2">
                  {isEdit ? "Edit Customer" : "Add Customer"}
                </div>
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={() => navigate("/customer")}
                >
                  Back
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-6 mt-4">
                  {/* First Name */}
                  <div className="flex flex-col text-gray-600">
                    <label>First Name *</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.firstName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.firstName && (
                      <span className="text-red-500 text-sm">{errors.firstName}</span>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="flex flex-col text-gray-600">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter last name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.lastName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                     {errors.lastName && (
                      <span className="text-red-500 text-sm">{errors.firstName}</span>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div className="flex flex-col text-gray-600">
                    <label>Phone Number *</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      value={userDetails.phoneNumber}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.phoneNumber ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
                    )}
                  </div>

                  {/* Email */}
                  <div className="flex flex-col text-gray-600">
                    <label>Email *</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      value={userDetails.email}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.email ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.email && (
                      <span className="text-red-500 text-sm">{errors.email}</span>
                    )}
                  </div>

                  {/* Status */}
                  <div className="flex flex-col text-gray-600">
                    <label>Status</label>
                    <select
                      name="status"
                      value={userDetails.status}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                    >
                      <option value="enabled">Enabled</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    {isEdit ? "Update" : "Save"}
                  </button>
                  <button
                    type="button"
                    onClick={handleReset}
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditCustomer;
