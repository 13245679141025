import React, { useState } from "react";
import {
  FaHome,
  FaCar,
  FaUsers,
  FaMap,
  FaMoneyBill,
  FaCog,
  FaBars,
  FaChevronDown,
  FaChevronUp,
  FaWallet,
  FaFileAlt, // Importing the new icon for "Page"
} from "react-icons/fa";
import { MdAssignment } from "react-icons/md";

import { MdOutlineSecurity } from "react-icons/md";
import { ImCoinDollar } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../State/sideBarSlice";

function Sidebar({ currentSelection = { main: "", sub: "" } }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const expanded = useSelector((state) => state.sidebar.expandedList);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSublist = (category) => {
    dispatch(toggleCategory(category));
  };

  return (
    <>
      {/* Hamburger Icon for mobile screens */}
      <div className="lg:hidden p-4">
        <FaBars className="text-2xl cursor-pointer" onClick={toggleSidebar} />
      </div>

      {/* Sidebar for larger screens and when open on smaller screens */}
      <div
        className={`bg-stone-100 w-64 h-screen fixed lg:static lg:translate-x-0 transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        style={{
          maxHeight: "100vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }} // Enable scrolling within the sidebar
      >
        <ul className="space-y-4 pb-10">
          {/* Dashboard */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "dashboard"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 pt-10 hover:bg-gray-200`}
              onClick={() => navigate("/Dashboard")}
            >
              <div className="flex items-center">
                <FaHome className="mr-2" />
                Dashboard
              </div>
            </div>
          </li>
          {/* Heatmap */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "Heatmap"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2`}
              onClick={() => navigate("/Heatmap")}
            >
              <div className="flex items-center">
                <FaMap className="mr-2" />
                Heatmap
              </div>
            </div>
          </li>
          {/* Live Users */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "liveusers"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/liveusers")}
            >
              <div className="flex items-center">
                <FaMap className="mr-2" />
                Live Users
              </div>
            </div>
          </li>
          {/* Users & Customers */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "users"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("users")}
            >
              <div className="flex items-center">
                <FaUsers className="mr-2" />
                Users & Customers
              </div>
              {expanded.users ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.users && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/users"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "users"
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      navigate("/users");
                    }}
                  >
                    Users
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/customer"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "customer"
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      navigate("/customer");
                    }}
                  >
                    Customer
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/driver"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "driver"
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      navigate("/driver");
                    }}
                  >
                    Driver
                  </a>
                </li>
              </ul>
            )}
          </li>
          {/* Assignments */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "assignments"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/assignments")}
            >
              <div className="flex items-center">
                <MdAssignment className="mr-2"/>
                Assignments
              </div>
            </div>
          </li>
          {/* Manage Pricing */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "managePricing"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/vehicles/fares")}
            >
              <div className="flex items-center">
                <FaWallet className="mr-2" />
                Manage Pricing
              </div>
            </div>
          </li>
          {/* Revenue Report */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "revenue"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/revenuereport")}
            >
              <div className="flex items-center">
                <FaMoneyBill className="mr-2" />
                Revenue Report
              </div>
            </div>
          </li> */}
          {/* Rides */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "rides"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("rides")}
            >
              <div className="flex items-center">
                <FaWallet className="mr-2" />
                Rides
              </div>
              {expanded.rides ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.rides && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/rides/add"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "addRide"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Add Ride
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/rides/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "AllRides"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    All Rides
                  </a>
                </li>

              </ul>
            )}
          </li>
          {/* Coupons */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "coupons"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("coupons")}
            >
              <div className="flex items-center">
                <FaCar className="mr-2" />
                Coupons
              </div>
              {expanded.coupons ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.coupons && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/coupons/add"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "addCoupon"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Add Coupon
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/coupons/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allCoupons"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    All Coupons
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* Cash Handling */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "cashHandling"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2`}
              onClick={() => navigate("/cashhandling")}
            >
              <div className="flex items-center">
                <ImCoinDollar className="mr-2" />
                Cash Handling
              </div>
            </div>
          </li>
          {/* Vehicles */}
          <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "vehicles"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("vehicles")}
            >
              <div className="flex items-center">
                <FaCar className="mr-2" />
                Vehicles
              </div>
              {expanded.vehicles ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.vehicles && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/all"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allVehicles"
                        ? "text-green-600 "
                        : "text-gray-600"
                    }`}
                  >
                    All Vehicles
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/brand"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "brand"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Brand
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/model"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "model"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Model
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/vehiclecategory"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "vehiclecategory"
                        ? "text-green-600 "
                        : "text-gray-600"
                    }`}
                  >
                    Vehicle Category
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/vehicles/colors"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "color"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Color
                  </a>
                </li>
              </ul>
            )}
          </li>
          {/* Security */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "security"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("security")}
            >
              <div className="flex items-center">
                <MdOutlineSecurity className="mr-2" />
                Security
              </div>
              {expanded.security ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.security && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/security/capabilities"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "capabilities"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Capabilities
                  </a>
                </li>
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/security/roles"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "roles"
                        ? "text-green-600 font-bold"
                        : "text-gray-600"
                    }`}
                  >
                    Roles
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* ***"Page" Main Menu *** */}
         {/* < li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "page"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => toggleSublist("page")}
            >
              <div className="flex items-center">
                <FaFileAlt className="mr-2" />
                Page
              </div>
              {expanded.page ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expanded.page && (
              <ul className="bg-gray-200 text-gray-600">
                <li className="w-full hover:bg-gray-300">
                  <a
                    href="/pages"
                    className={`block w-full px-6 py-2 ${
                      currentSelection.sub === "allPages"
                        ? "text-green-600"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      navigate("/pages/all");
                    }}
                  >
                    All Pages
                  </a>
                </li>
              </ul>
            )}
          </li> */}
          {/* Settings */}
          {/* <li className="mb-4">
            <div
              className={`flex justify-between items-center cursor-pointer ${
                currentSelection.main === "settingsform"
                  ? "text-green-600 font-bold"
                  : "text-gray-600"
              } px-6 py-2 hover:bg-gray-200`}
              onClick={() => navigate("/SettingsForm")}
            >
              <div className="flex items-center">
                <FaCog className="mr-2" />
                Settings
              </div>
            </div>
          </li> */}
        </ul>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
}

export default Sidebar;
