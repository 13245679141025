// src/pages/Vehicles/AddEditVehicle.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner";

function AddEditVehicle() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const location = useLocation();
  const { vehicleId } = useParams();
  const editVehicle = location.state?.vehicle;

  const [vehicleDetails, setVehicleDetails] = useState({
    modelMasterId: "",
    insuranceDetails: "",
    vehicleRegistration: "",
    vehicleStatus: "",
  });

  const [errors, setErrors] = useState({});
  const [availableModels, setAvailableModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false); 

  // Fetch available models for the model dropdown
  useEffect(() => {
    const fetchModels = async () => {
      setLoadingData(true);
      try {
        const response = await vehicleAPI.getModelsAPI(1, 100,{},token);
        setAvailableModels(response.data.items || []);
      } catch (err) {
        console.error("Failed to fetch models:", err);
        alert("Failed to load models. Please try again.");
      } finally {
        setLoadingData(false);
      }
    };
    fetchModels();
  }, []);

  // If editing, set the existing vehicle details
  useEffect(() => {
    if (vehicleId && editVehicle) {
      console.log("Edit: ", editVehicle);
      setVehicleDetails({
        modelMasterId: editVehicle.modelMasterId || "",
        insuranceDetails: editVehicle.insuranceDetails || "", // Changed from object to string
        vehicleRegistration: editVehicle.vehicleRegistration || "",
        vehicleStatus: editVehicle.vehicleStatus || "",
      });
    }
  }, [vehicleId, editVehicle]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Since insuranceDetails is now a string, no need for nested handling
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Validation function
  const validate = () => {
    const newErrors = {};

    // Model ID: Required
    if (!vehicleDetails.modelMasterId) {
      newErrors.modelMasterId = "Please select a model.";
    }

    // Insurance Details: Required and must be a non-empty string
    if (!vehicleDetails.insuranceDetails.trim()) {
      newErrors.insuranceDetails = "Insurance details are required.";
    }

    // Vehicle Registration: Required and must match a pattern (e.g., license plate format)
    if (!vehicleDetails.vehicleRegistration.trim()) {
      newErrors.vehicleRegistration = "Vehicle registration is required.";
    } else if (
      !/^[A-Z0-9-]+$/i.test(vehicleDetails.vehicleRegistration.trim())
    ) {
      newErrors.vehicleRegistration =
        "Please enter a valid registration format.";
    }

    // Vehicle Status: Must be either "Published" or "Draft"
    if (!["published", "draft"].includes(vehicleDetails.vehicleStatus)) {
      newErrors.vehicleStatus = "Please select a valid status.";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    try {
      let response;
      if (vehicleId) {
        // Editing existing vehicle
        response = await vehicleAPI.editVehicleAPI(vehicleDetails, vehicleId,token);
      } else {
        // Adding new vehicle
        response = await vehicleAPI.addVehicleAPI(vehicleDetails,token);
        console.log(response);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Vehicle ${vehicleId ? "updated" : "added"} successfully!`);
        navigate("/vehicles/all");
      } else {
        console.log("Something went wrong. Please try again.");
        const errorMessage =
          response.data?.error || "Something went wrong. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      // Extract error message from the error object
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "An error occurred while submitting the form.";
      alert(errorMessage);
    }
  };

  // Handle form reset
  const handleReset = () => {
    if (vehicleId) {
      // If editing, navigate back to the vehicles list to prevent accidental data loss
      navigate("/vehicles/all");
    } else {
      // If adding, clear the form
      setVehicleDetails({
        modelMasterId: "",
        insuranceDetails: "",
        vehicleRegistration: "",
        vehicleStatus: "",
      });
      setErrors({});
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "allVehicles" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {vehicleId ? "Edit Vehicle" : "Add New Vehicle"}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/all");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mt-4">
                  {/* Model */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="modelMasterId">Model *</label>
                    <select
                      id="modelMasterId"
                      name="modelMasterId"
                      value={vehicleDetails.modelMasterId}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.modelMasterId
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.modelMasterId ? "true" : "false"}
                    >
                      <option value="">Select Model</option>
                      {availableModels.map((model) => (
                        <option
                          key={model.modelMasterId}
                          value={model.modelMasterId}
                        >
                          {model.modelName} ({model.year})
                        </option>
                      ))}
                    </select>
                    {errors.modelMasterId && (
                      <span className="text-red-500 text-sm">
                        {errors.modelMasterId}
                      </span>
                    )}
                  </div>

                  {/* Insurance Details */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="insuranceDetails">
                      Insurance Details *
                    </label>
                    <input
                      type="text"
                      id="insuranceDetails"
                      name="insuranceDetails" // Changed from "insuranceDetails.policyNumber"
                      placeholder="Enter insurance details"
                      value={vehicleDetails.insuranceDetails}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.insuranceDetails
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.insuranceDetails ? "true" : "false"}
                    />
                    {errors.insuranceDetails && (
                      <span className="text-red-500 text-sm">
                        {errors.insuranceDetails}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Registration */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleRegistration">
                      Vehicle Registration *
                    </label>
                    <input
                      type="text"
                      id="vehicleRegistration"
                      name="vehicleRegistration"
                      placeholder="Enter registration number"
                      value={vehicleDetails.vehicleRegistration}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.vehicleRegistration
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={
                        errors.vehicleRegistration ? "true" : "false"
                      }
                    />
                    {errors.vehicleRegistration && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleRegistration}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleStatus">Vehicle Status *</label>
                    <select
                      id="vehicleStatus"
                      name="vehicleStatus"
                      value={vehicleDetails.vehicleStatus}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.vehicleStatus
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.vehicleStatus ? "true" : "false"}
                    >
                      <option value="">Select Status</option>
                      <option value="published">Published</option>
                      <option value="draft">Draft</option>
                      {/* Add more status options as needed */}
                    </select>
                    {errors.vehicleStatus && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleStatus}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={handleReset}
                  >
                    {vehicleId ? "Cancel" : "Reset"}
                  </button>
                </div>
              </form>
              {loading && (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditVehicle;
