// http://localhost:3000/api/adminpanel/getridebustatus/:pageNumber/:itemsPerPage'

import { ADMIN_ROUTE, BASE_URL, GET_RIDES } from "./apiEndPoint";
import { apiPostFetcher } from "./apiFetcher";


const adminRoute = `${BASE_URL}${ADMIN_ROUTE}`

/**
 * To get all brands also supports search
 * @param {Number} page - Page to be retreived
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getRidesAPI = async (page, items, payload,token) => {
    let url = `${adminRoute}${GET_RIDES}/${page}/${items}`;
    return apiPostFetcher(url, payload,token);
  };

const ridesAPI = {
    getRidesAPI
}

export default ridesAPI