import React from "react";
import { useDispatch } from "react-redux";
import { logout, setAccessToken } from "../State/userSlice";
import { useNavigate } from "react-router-dom";
import img from "../Asset/profile.png"

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <nav className="bg-white shadow-md py-4 h-[8vh] px-6 w-full flex justify-between items-center fixed top-0 z-10">
      <div className="flex items-center">
        <h1 className="text-green-600 text-2xl font-bold mb-2">WOA</h1>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500 mr-4">Hello, User! 👋</span>
        <img
          src={img}
          alt="User Avatar"
          className="rounded-full w-10 h-10"
        />
        <button className="border border-red-800 text-sm font-semibold rounded-md ml-8 p-1 text-red-800" onClick={()=>{
          dispatch(logout);
          navigate("/");
        }}>
          Logout
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
