import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import DriverList from "./Pages/Driver/DriverList";
import AddEditDriver from "./Pages/Driver/AddEditDriver";
import CustomerList from "./Pages/Customer/CustomerList";
import AddEditCustomer from "./Pages/Customer/AddEditCustomer";
import AllRides from "./Pages/Rides/AllRides"
import AllCoupons from "./Pages/Coupons/AllCoupons";
import AddNewCoupons from "./Pages/Coupons/AddNewCoupon";
import RevenueReport from "./Pages/RevenueReport/RevenueReport";
import ManagePricing from "./Pages/ManagePricing/ManagePricing";
import CashHandling from "./Pages/CashHandling/CashHandling";
import SettingsForm from './Pages/Settings/Settings';
import CapabilitiesTable from "./Pages/Security/Capabilities";
import RolesTable from "./Pages/Security/Roles";
import PageList from "./Pages/PageList/PageList";
import Heatmap from "./Pages/Heatmap/Heatmap";
import LiveUsers from "./Pages/LiveUsers/LiveUsers";
import RideBookingForm from "./Pages/Rides/AddNewRide";
import BrandsList from "./Pages/Vehicles/BrandsList";
import AddEditBrand from "./Pages/Vehicles/AddEditBrand";
import ModelsList from "./Pages/Vehicles/ModelsList";
import AddEditModel from "./Pages/Vehicles/AddEditModel";
import ColorsList from "./Pages/Vehicles/ColorsList";
import AddEditColor from "./Pages/Vehicles/AddEditColor";
import VehiclesList from "./Pages/Vehicles/VehiclesList";
import AddEditVehicle from "./Pages/Vehicles/AddEditVehicle";
import useTokenRefresh from "./hooks/useTokenRefresh";
import AssignList from "./Pages/Driver/AssignList";
import UserList from "./Pages/User/UserList";
import AddEditUser from "./Pages/User/AddEditUser";
import CategoriesList from "./Pages/Vehicles/vehicleCategoryList";
import FaresTable from "./Pages/Vehicles/faresTableList";
import AddEditFare from "./Pages/Vehicles/AddEditFare";
import AddEditVehicleCategory from "./Pages/Vehicles/AddEditVehicleCategory";
import { Toaster } from 'sonner';
import RideTable from "./Pages/Rides/RideDetails";

function App() {
  useTokenRefresh();
  return (
    <Router>
       <Toaster richColors /> 
      <Routes>
     
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/coupons/all' element={<AllCoupons />} />
        <Route path='/coupons/add' element={<AddNewCoupons />} />
        <Route path='/rides/all' element={<AllRides />} />
        <Route path='/' element={<Login />} />
        <Route path='/driver' element={<DriverList />} />
        <Route path='/users' element={<UserList />} />
        <Route path='/users/add-user' element={<AddEditUser />} />
        <Route path='/edit-user/:userId' element={<AddEditUser />} />
        <Route path='customer' element={<CustomerList />} />
        <Route path='/add-driver' element={<AddEditDriver />} />
        <Route path='/edit-driver/:driverId' element={<AddEditDriver />} />
        <Route path='/assignments' element={<AssignList />} />
        <Route path='/add-customer' element={<AddEditCustomer />} />
        <Route path='/vehicles/all' element={<VehiclesList />} />
        <Route path='/revenuereport' element={<RevenueReport />} />
        <Route path='/managepricing' element={<ManagePricing />} />
        <Route path='/cashhandling' element={<CashHandling />} />
        <Route path='/SettingsForm' element={<SettingsForm />} />
        <Route path='/security/capabilities' element={<CapabilitiesTable />} />
        <Route path='/security/roles' element={<RolesTable />} />
        <Route path='/Heatmap' element={<Heatmap />} />
        <Route path='/LiveUsers' element={<LiveUsers />} />
        <Route path='/rides/add' element={<RideBookingForm />} />
        <Route path='/vehicles/brand' element={<BrandsList />} />
        <Route path='/vehicles/model' element={<ModelsList />} />
        <Route path='/pages' element={<PageList />} />
        <Route path='/vehicles/add-brand' element={<AddEditBrand />} />
        <Route path='/vehicles/edit-brand/:brandId' element={<AddEditBrand />} />
        <Route path='/vehicles/add-model' element={<AddEditModel />} />
        <Route path='/vehicles/edit-model/:modelId' element={<AddEditModel />} />
        <Route path='/vehicles/colors' element={<ColorsList />} />
        <Route path='/vehicles/add-color' element={<AddEditColor />} />
        <Route path='/vehicles/edit-color/:colorId' element={<AddEditColor />} />
        <Route path='/vehicles/add-vehicle' element={<AddEditVehicle />} />
        <Route path='/vehicles/edit-vehicle/:vehicleId' element={<AddEditVehicle />} />
        <Route path='/driver/assignlist' element={<AssignList />} />
        <Route path='/vehicles/vehiclecategory' element={<CategoriesList />} />
        <Route path='/vehicles/add-vehicle-category' element={<AddEditVehicleCategory/>}/>
        <Route path='/vehicles/edit-vehicle-category/:categoryId' element={<AddEditVehicleCategory/>}/>
        <Route path='/vehicles/fares' element={<FaresTable />} />
        <Route path='/vehicles/fares/add' element={<AddEditFare />} />
        <Route path="/vehicles/fares/edit/:fareId" element={<AddEditFare />} />
        <Route path="/RideTable" element={<RideTable/>} />

      </Routes>
    </Router>
  );
}

export default App;
