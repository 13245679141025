import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { FaEye } from "react-icons/fa";
import Pagination from "../../Components/Pagination";
import { fakeApiCashDetails } from "../../SampleData/fakeApiUserData";
import { useNavigate } from "react-router-dom"; // Ensure react-router-dom is installed
import { useDispatch } from "react-redux"; // Ensure react-redux is installed

function CashHandling() {
  // State for drivers data
  const [drivers, setDrivers] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for search and filter
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to fetch drivers from the fake API
  const fetchDrivers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fakeApiCashDetails({
        page: currentPage,
        limit: itemsPerPage,
        searchQuery,
        statusFilter,
      });

      if (Array.isArray(response.cash)) {
        setDrivers(response.cash);
        setTotalPages(response.totalPages || 1);
        setTotalItems(response.totalItems || 0);
        console.log("setDrivers: ", drivers);
      } else {
        setDrivers([]);
        setTotalPages(1);
        setTotalItems(0);
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching drivers.");
      setDrivers([]); // Clear drivers on error to avoid undefined issues
    } finally {
      setLoading(false);
    }
  };

  // Fetch drivers when currentPage, itemsPerPage, searchQuery, or statusFilter changes
  useEffect(() => {
    fetchDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchQuery, statusFilter]);

  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle status filter change
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page on filter
  };

  // Handle filter button click
  const handleFilter = () => {
    fetchDrivers();
  };

  // Handle reset button click
  const handleReset = () => {
    setSearchQuery("");
    setStatusFilter("");
    setCurrentPage(1);
  };

  // Derived state: displayedDrivers
  const displayedDrivers = drivers;

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "cashHandling", sub: null }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Driver List
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              {/* Search Input */}
              <div className="flex flex-col justify-stretch text-gray-600 mb-4 md:mb-0">
                <label htmlFor="search">Search</label>
                <input
                  id="search"
                  type="text"
                  placeholder="Search Drivers"
                  className="border border-gray-300 rounded p-2 w-full"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-green-700 text-white rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Driver List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Driver List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Driver List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">Driver ID</div>
                <div className="flex-1 text-left">Name</div>
                <div className="flex-1 basis-40 text-left">Email</div>
                <div className="flex-1 text-center">Wallet Amount</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex justify-center p-4 text-red-500">
                  {error}
                </div>
              ) : displayedDrivers.length > 0 ? (
                displayedDrivers.map((driver) => (
                  <div
                    key={driver.id}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 text-woagreen text-left">
                      #{driver.DriverID}
                    </div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {driver.Name}
                    </div>
                    <div className="flex-1 basis-40 overflow-hidden text-left">
                      {driver.Email}
                    </div>
                    <div className="flex-1 text-center">
                      {driver.WalletAmount}
                    </div>
                    <div className="flex-1 flex justify-end ">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-l-lg p-4 text-xs h-8 hover:bg-green-100"
                        onClick={() => {
                          // Dispatch collection action.
                          dispatch({
                            type: "COLLECT_CASH",
                            payload: driver.id,
                          });
                        }}
                      >
                        Collect
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-r-lg p-4 text-lg h-8 hover:bg-red-100"
                        onClick={() => {
                          //View Driver Details
                          navigate("/viewdriver");
                        }}
                      >
                        <FaEye />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No drivers found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashHandling;
