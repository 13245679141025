// src/Pages/Rides/AllRides.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import RideCard from "../../Components/RideCard";
import RideTable from "./RideDetails";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import ridesAPI from "../../utils/API/rideApis"; // Adjust the path as necessary
import useAuthToken from "../../hooks/customHooks";

// Define map container style
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

// Define polyline options
const polylineOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

function AllRides() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isSticky, setIsSticky] = useState(true); // State for sticky map
  const [path, setPath] = useState([]); // State for the route path
  const ridesPerPage = 10;
  const mapRef = useRef(null);

  // Filter states
  const [status, setStatus] = useState("Accepted"); // Updated to lowercase
  const [searchString, setSearchString] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [searchByDate, setSearchByDate] = useState(false);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [selectedRide, setSelectedRide] = useState(null);

  // Update initialCenter based on selectedRide
  const initialCenter = selectedRide
    ? {
        lat:
          selectedRide.type === "RideRequest"
            ? parseFloat(selectedRide.pickupLatitude)
            : parseFloat(selectedRide.pickupLat),
        lng:
          selectedRide.type === "RideRequest"
            ? parseFloat(selectedRide.pickupLongitude)
            : parseFloat(selectedRide.pickupLong),
      }
    : {
        lat: 37.419734,
        lng: -122.0827784,
      };

  // Rides data state
  const [ridesData, setRidesData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Authentication token (assuming you have an Auth context)
  const token = useAuthToken(); // Adjust based on your auth implementation

  // Function to fetch rides based on filters
  const fetchRides = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const payload = {
        status: status !== "Accepted" ? status : "Accepted",
        searchString,
        customerId,
        driverId,
        ...(searchByDate && { dateRange }), // Include dateRange if searchByDate is true
      };

      const response = await ridesAPI.getRidesAPI(
        currentPage,
        ridesPerPage,
        payload,
        token
      );

      if (response.error) {
        throw new Error(response.error);
      }

      const data = response.data;

      // Assuming the API returns an object with 'rides' array and 'pagination' object
      setRidesData(data.rides);
      setTotalPages(data.pagination.totalPages);
    } catch (err) {
      console.error("Error fetching rides:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [
    status,
    searchString,
    customerId,
    driverId,
    searchByDate,
    dateRange,
    currentPage,
    ridesPerPage,
    token,
  ]);

  useEffect(() => {
    fetchRides();
  }, [fetchRides]);

  // Function to handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to compute the route and set the path
  const computeRoute = useCallback(async () => {
    if (!selectedRide) {
      console.error("No selected ride");
      return;
    }

    // Initialize variables for coordinates
    let plat, plong, dlat, dlong;

    // Assign coordinate values based on rideType
    if (selectedRide.type === "RideRequest") {
      plat = selectedRide?.pickupLatitude;
      plong = selectedRide?.pickupLongitude;
      dlat = selectedRide?.destinationLatitude;
      dlong = selectedRide?.destinationLongitude;
    } else {
      plat = selectedRide?.pickupLat;
      plong = selectedRide?.pickupLong;
      dlat = selectedRide?.destinationLat;
      dlong = selectedRide?.destinationLong;
    }

    // Check for invalid coordinates
    if (
      isNaN(parseFloat(plat)) ||
      isNaN(parseFloat(plong)) ||
      isNaN(parseFloat(dlat)) ||
      isNaN(parseFloat(dlong))
    ) {
      console.error("Invalid coordinates", { plat, plong, dlat, dlong });
      return;
    }

    // Define origin and destination using the correct coordinates
    const origin = {
      location: {
        latLng: {
          latitude: parseFloat(plat),
          longitude: parseFloat(plong),
        },
      },
    };

    const destination = {
      location: {
        latLng: {
          latitude: parseFloat(dlat),
          longitude: parseFloat(dlong),
        },
      },
    };

    try {
      const response = await fetch(
        "https://routes.googleapis.com/directions/v2:computeRoutes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key":"AIzaSyBV5RTMe05Gbc3ltv1bnHy9ysH_L1W_SSM",
            "X-Goog-FieldMask":
              "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
          },
          body: JSON.stringify({
            origin,
            destination,
            travelMode: "DRIVE",
            routingPreference: "TRAFFIC_AWARE",
            computeAlternativeRoutes: false,
            routeModifiers: {
              avoidTolls: false,
              avoidHighways: false,
              avoidFerries: false,
            },
            languageCode: "en-US",
            units: "IMPERIAL",
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message ||
            `API request failed with status ${response.status}`
        );
      }

      const data = await response.json();
      const encodedPolyline = data.routes[0].polyline.encodedPolyline;

      // Decode the polyline using Google Maps API
      const decodedPath =
        window.google.maps.geometry.encoding.decodePath(encodedPolyline);

      // Convert to array of { lat, lng }
      const pathCoordinates = decodedPath.map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

      setPath(pathCoordinates);
    } catch (error) {
      console.error("Error computing the route:", error);
    }
  }, [selectedRide]);

  useEffect(() => {
    if (window.google && selectedRide) {
      computeRoute();
    }
  }, [selectedRide, computeRoute]);

  useEffect(() => {
    if (selectedRide && mapRef.current) {
      let lat, lng;
      if (selectedRide.type === "RideRequest") {
        lat = parseFloat(selectedRide.pickupLatitude);
        lng = parseFloat(selectedRide.pickupLongitude);
      } else {
        lat = parseFloat(selectedRide.pickupLat);
        lng = parseFloat(selectedRide.pickupLong);
      }
      if (!isNaN(lat) && !isNaN(lng)) {
        console.log("Panning to:", lat, lng); // Debugging
        mapRef.current.panTo({ lat, lng });
      } else {
        console.error("Invalid coordinates for panning:", selectedRide);
      }
    }
  }, [selectedRide]);

  const handleLoad = useCallback(() => {
    computeRoute();
  }, [computeRoute]);

  const handleRideSelect = (ride) => {
    console.log("Selected Ride:", ride); // Debugging
    setSelectedRide(ride);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-12">
        <Sidebar currentSelection={{ main: "rides", sub: "AllRides" }} />
        {/* Main content area */}
        <div className="flex-1 bg-gray-50 overflow-y-auto h-screen">
          <div className="p-3">
            <h1 className="text-4xl py-10 mb-5 font-weight:100 text-gray-500">
              Dashboard
            </h1>
            <h1 className="text-xl px-3 bg-green-600 py-5 text-gray-50">
              All Rides
            </h1>
            <div className="flex h-auto">
              <div className="w-[32%] bg-white p-3">
                {/* Search fields and filters here */}
                <div className="mb-3">
                  <label className="block text-gray-400 mb-1" htmlFor="search">
                    Search
                  </label>
                  <input
                    type="text"
                    id="search"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    className="border border-gray-400 rounded-lg px-3 py-1 w-full"
                    placeholder="Search..."
                  />
                </div>

                {/* Search by Date Checkbox */}
                <div className="flex items-center mb-3">
                  <input
                    type="checkbox"
                    id="search-by-date"
                    checked={searchByDate}
                    onChange={(e) => setSearchByDate(e.target.checked)}
                    className="h-4 w-4 text-green-600 border border-gray-400 rounded focus:ring-green-500"
                  />
                  <label
                    htmlFor="search-by-date"
                    className="ml-2 text-gray-400"
                  >
                    Search by Date
                  </label>
                </div>

                {searchByDate && (
                  <div className="mb-3">
                    <label className="block text-gray-400 mb-1">
                      Date Range
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="date"
                        value={dateRange.from}
                        onChange={(e) =>
                          setDateRange((prev) => ({
                            ...prev,
                            from: e.target.value,
                          }))
                        }
                        className="border border-gray-400 rounded-lg px-3 py-1 w-1/2"
                      />
                      <input
                        type="date"
                        value={dateRange.to}
                        onChange={(e) =>
                          setDateRange((prev) => ({
                            ...prev,
                            to: e.target.value,
                          }))
                        }
                        className="border border-gray-400 rounded-lg px-3 py-1 w-1/2"
                      />
                    </div>
                  </div>
                )}

                {/* First row of filters */}
                <div className="flex space-x-3 mb-3">
                  {/* Status Field */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="Accepted">Accepted</option>
                      <option value="Started">Started</option>
                      <option value="Reached Location">Reached Location</option>
                      <option value="Completed">Completed</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>

                  {/* Type Field */}
                  <div className="w-1/2">
                    <label className="block text-gray-400 mb-1" htmlFor="type">
                      Type
                    </label>
                    <select
                      id="type"
                      // Implement type filter as needed
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="all">All Type</option>
                      <option value="instant">Instant</option>
                      <option value="scheduled">Scheduled</option>
                    </select>
                  </div>
                </div>

                {/* Second row of filters */}
                <div className="flex space-x-3 mb-3">
                  {/* By Driver Field */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="driver"
                    >
                      By Driver
                    </label>
                    <select
                      id="driver"
                      value={driverId}
                      onChange={(e) => setDriverId(e.target.value)}
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="">Select Driver</option>
                      {/* Populate with driver options */}
                    </select>
                  </div>

                  {/* By Customer Field */}
                  <div className="w-1/2">
                    <label
                      className="block text-gray-400 mb-1"
                      htmlFor="customer"
                    >
                      By Customer
                    </label>
                    <select
                      id="customer"
                      value={customerId}
                      onChange={(e) => setCustomerId(e.target.value)}
                      className="border text-gray-600 border-gray-400 rounded-lg px-3 py-1 w-full"
                    >
                      <option value="">Select Customer</option>
                      {/* Populate with customer options */}
                    </select>
                  </div>
                </div>

                {/* Action buttons */}
                <div className="flex justify-between px-10 mt-6 mb-6">
                  <button
                    onClick={() => {
                      setCurrentPage(1);
                      fetchRides();
                    }}
                    className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700"
                  >
                    Refresh
                  </button>
                  <button
                    onClick={() => {
                      // Clear all filters
                      setStatus("all");
                      setSearchString("");
                      setCustomerId("");
                      setDriverId("");
                      setSearchByDate(false);
                      setDateRange({ from: "", to: "" });
                      setCurrentPage(1);
                      fetchRides();
                    }}
                    className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-500"
                  >
                    Clear Filters
                  </button>
                </div>

                {/* Loading and Error Messages */}
                {loading && (
                  <p className="text-center text-blue-500">Loading rides...</p>
                )}
                {error && (
                  <p className="text-center text-red-500">Error: {error}</p>
                )}

                {/* Ride Cards */}
                {!loading && !error && ridesData.length === 0 && (
                  <p className="text-center text-gray-500">No rides found.</p>
                )}
                {!loading &&
                  !error &&
                  ridesData.map((ride) => (
                    <RideCard
                      key={ride.id}
                      rideData={ride}
                      onSelect={handleRideSelect}
                    />
                  ))}

                {/* Pagination */}
                {!loading && !error && totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                      (pageNumber) => (
                        <button
                          key={pageNumber}
                          onClick={() => {
                            paginate(pageNumber);
                            fetchRides();
                          }}
                          className={`mx-1 px-3 py-1 rounded ${
                            pageNumber === currentPage
                              ? "bg-green-600 text-white"
                              : "bg-gray-300 text-gray-400"
                          }`}
                        >
                          {pageNumber}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>

              <div
                className={`relative flex-1 items-center px-6 py-16 h-[90vh] bg-white ${
                  isSticky ? "sticky top-0" : ""
                }`}
                id="map"
              >
                <div className="absolute w-[90%] bottom-20 left-10 right-0 bg-white p-1 max-h-[30%] z-50">
                  <RideTable ride={selectedRide} />
                </div>
                <LoadScript
                  googleMapsApiKey="AIzaSyBV5RTMe05Gbc3ltv1bnHy9ysH_L1W_SSM"
                  libraries={["geometry"]}
                  onLoad={handleLoad}
                  onError={(error) => {
                    console.error("Error loading Google Maps API:", error);
                    setError(
                      "Failed to load Google Maps. Please try again later."
                    );
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={initialCenter}
                    zoom={14}
                    onLoad={(map) => {
                      mapRef.current = map;
                    }}
                  >
                    {selectedRide && (
                      <>
                        <Marker
                          position={{
                            lat: parseFloat(
                              selectedRide?.pickupLat ||
                                selectedRide?.pickupLatitude
                            ),
                            lng: parseFloat(
                              selectedRide?.pickupLong ||
                                selectedRide?.pickupLongitude
                            ),
                          }}
                          label="A"
                        />

                        <Marker
                          position={{
                            lat: parseFloat(
                              selectedRide?.destinationLat ||
                                selectedRide?.destinationLatitude
                            ),
                            lng: parseFloat(
                              selectedRide?.destinationLong ||
                                selectedRide?.destinationLongitude
                            ),
                          }}
                          label="B"
                        />

                        {path.length > 0 && (
                          <Polyline path={path} options={polylineOptions} />
                        )}
                      </>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllRides;
