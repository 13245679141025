import { ADMIN_AUTH } from "./apiEndPoint";
import { apiPostFetcher } from "./apiFetcher";
import { store } from "../../State/store";
import { setAccessToken } from "../../State/userSlice";

/**
 * Login API.
 * @param {Object} body - The data to be sent in the request body.
 * @returns {Promise} The response data.
 */
const loginApi = async (payload) => {
  let url = `${ADMIN_AUTH}/user-login`;
  return apiPostFetcher(url, payload);
};

/**
 * Function to refresh the access token using the refresh token.
 * @returns {Promise<string|null>} The new access token or null if failed.
 */
export const refreshAccessToken = async () => {
  const state = store.getState();
  const refreshToken = state.user.refreshToken;

  if (!refreshToken) {
    console.warn('No refresh token available');
    return null;
  }

  try {
    const response = await apiPostFetcher(`${ADMIN_AUTH}/token`,refreshToken);

    if (response.error) {
      console.error('Failed to refresh access token:', response.error);
      return null;
    }

    const { accessToken: newAccessToken } = response.data;
    store.dispatch(setAccessToken(newAccessToken));
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

const createUserApi = async () =>{
  
}

const authApis = {
  loginApi,

};

export default authApis;
