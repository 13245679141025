// src/redux/slices/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEdit: false,
  editElement: {},
  accessToken: null,
  refreshToken: null, // Added refreshToken to the state
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsEdit(state, action) {
      state.isEdit = action.payload;
    },
    setEditElement(state, action) {
      state.editElement = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setRefreshToken(state, action) { // Action to set refreshToken
      state.refreshToken = action.payload;
    },
    logout(state) { // Action to handle logout by clearing tokens and other states
      state.isEdit = false;
      state.editElement = {};
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { 
  setIsEdit, 
  setEditElement, 
  setAccessToken, 
  setRefreshToken,
  logout 
} = userSlice.actions;

export default userSlice.reducer;
