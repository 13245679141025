//src/Pages/Rides/RideDetails.js
import React, { useState } from "react";

const RideTable = ({ ride }) => {
  console.log("Ride Details:", ride);
  const [activeTab, setActiveTab] = useState("Overview");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="relative">
      {/* Overlay Content */}
      <div className="absolute bottom-0 left-0 right-0 bg-white rounded-t-lg shadow-lg">
        {/* Tabs */}
        <div className="flex justify-around border-b">
          {[
            "Overview",
            "Vehicle",
            "Driver Details",
            "Customer Details",
            "Payment",
          ].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              className={`py-2 px-4 text-sm font-semibold ${
                activeTab === tab
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Content for each tab */}
        {activeTab === "Overview" && (
          <div className="p-4 max-h-64 overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Created by
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Pickup location
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Drop location
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Ride type
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Date & time
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Price model
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Distance
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Time taken
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Remark
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.createdBy}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.pickupPoint || ride?.pickup}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.destinationPoint || ride?.drop}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.rideType}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.createdAt}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.priceModel}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.distance}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.timeTaken}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.remark}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.rating}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "Vehicle" && (
          <div className="p-4 text-sm text-gray-700">
            <p>
              <strong>Brand:</strong> {ride?.vehicle?.vehicleMake}
            </p>
            <p>
              <strong>Model:</strong> {ride?.vehicle?.vehicleModel}
            </p>
            <p>
              <strong>Color:</strong> {ride?.vehicle?.color}
            </p>
            <p>
              <strong>Plate Number:</strong> {ride?.vehicle?.vehicleRegistration}
            </p>
          </div>
        )}

        {activeTab === "Driver Details" && (
          <div className="p-4 text-sm text-gray-700">
            <div className="flex items-center space-x-4">
              <div className="h-16 w-16 bg-gray-200 flex items-center justify-center">
                {ride?.imagePlaceholder}
              </div>
              <div>
                <p>
                  <strong>{ride?.name}</strong>{" "}
                </p>
                <p>
                  <strong>Name:</strong> {ride?.driver?.firstName + " " + ride?.driver?.lastName}
                </p>
                <p>
                  <strong>Phone:</strong> {ride?.driver?.phone}
                </p>
              </div>
            </div>
          </div>
        )}

        {activeTab === "Customer Details" && (
          <div className="p-4 text-sm text-gray-700">
            <div className="flex items-center space-x-4">
              <div className="h-16 w-16 bg-gray-200 flex items-center justify-center">
                {ride?.imagePlaceholder}
              </div>
              <div>
                <p>
                  <strong>{ride?.name}</strong>{" "}
                  
                </p>
                <p>
                  <strong>Name:</strong> {ride?.customer?.firstName + " " + ride?.customer?.lastName}
                </p>
                <p>
                  <strong>Phone:</strong> {ride?.customer?.phoneNumber}
                </p>
              </div>
            </div>
          </div>
        )}

        {activeTab === "Payment" && (
          <div className="p-4 overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Time taken
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Distance
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Payment method
                  </th>
               
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Payable amt.
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.timeTaken}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.distance}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.paymentType || ride?.payment_method || "NA"}
                  </td>
               
              
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.fare}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-700">
                    {ride?.paymentStatus || "NA"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RideTable;
