import { ADMIN_AUTH, GET_USERS_BY_ROLE } from "./apiEndPoint";
import { apiGetFetcher, apiPostFetcher, apiPutFetcher } from "./apiFetcher";

/**
 * To get all the users by role.
 * @param {Object} payload - Search by brand name and status.
 */
const getUsersAPI = async (token) => {
  let url = `${ADMIN_AUTH}${GET_USERS_BY_ROLE}`;
  return apiGetFetcher(url, token);
};

/**
 * To get all the users by role.
 * @param {Object} payload - Search by brand name and status.
 */
const getUsersPgnAPI = async (page,items,payload,token) => {
  let url = `${ADMIN_AUTH}${GET_USERS_BY_ROLE}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * To add/register a user with administrative privileges.
 * @param {Object} payload - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const addUserAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPostFetcher(url,payload,token);
}

/**
 * To update a user without password.
 * @param {Object} payload - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const updateUserAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPutFetcher(url,payload,token);
}

/**
 * To update a user's details.
 * @param {Object} newPassword - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const editUserAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPutFetcher(url,payload,token);
}

/**
 * To update a user's password.
 * @param {Object} newPassword - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const editUserPasswordAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPutFetcher(url,payload,token);
}




const usersApi = {
  getUsersAPI,
  addUserAPI,
  editUserAPI,
  updateUserAPI,
  getUsersPgnAPI,
  editUserPasswordAPI
};

export default usersApi;
