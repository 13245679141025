// src/Pages/Vehicles/ManagePricing.jsx

import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal
import { toast } from "sonner"; // Import Sonner for toast notifications

function ManagePricing() {
  const [prices, setPrices] = useState({
    oneHour: "",
    twoHour: "",
    threeHour: "",
    fourHour: "",
    fiveHour: "",
    sixHour: "",
    sevenHour: "",
    eightHour: "",
    nineHour: "",
    tenHour: "",
    elevenHour: "",
    twelveHour: "",
  });

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrices({ ...prices, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      // Replace this with your actual API call
      // Example:
      // await api.updatePricing(prices);
      console.log(prices); // Placeholder for form submission
      toast.success("Pricing updated successfully!");
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update pricing. Please try again.");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Open confirmation modal instead of directly submitting
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "managePricing", sub: null }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            <form
              onSubmit={handleFormSubmit}
              className="bg-gray-50 shadow-lg rounded-lg"
            >
              <h3 className="text-md font-light text-white bg-woagreen p-6 rounded-t-lg mb-4">
                Manage Ride Pricing
              </h3>

              {[
                { label: "Hourly - 1 hr in (AED)", name: "oneHour" },
                { label: "Hourly - 2 hr in (AED)", name: "twoHour" },
                { label: "Hourly - 3 hr in (AED)", name: "threeHour" },
                { label: "Hourly - 4 hr in (AED)", name: "fourHour" },
                { label: "Hourly - 5 hr in (AED)", name: "fiveHour" },
                { label: "Hourly - 6 hr in (AED)", name: "sixHour" },
                { label: "Hourly - 7 hr in (AED)", name: "sevenHour" },
                { label: "Hourly - 8 hr in (AED)", name: "eightHour" },
                { label: "Hourly - 9 hr in (AED)", name: "nineHour" },
                { label: "Hourly - 10 hr in (AED)", name: "tenHour" },
                { label: "Hourly - 11 hr in (AED)", name: "elevenHour" },
                { label: "Hourly - 12 hr in (AED)", name: "twelveHour" },
              ].map((item) => (
                <div key={item.name} className="px-6 mb-4">
                  <label
                    htmlFor={item.name}
                    className="block text-gray-600 text-md font-light mb-2"
                  >
                    {item.label} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number" // Changed to number for better UX
                    id={item.name}
                    name={item.name}
                    value={prices[item.name]}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                    placeholder="Enter price"
                    required
                    min="0"
                  />
                </div>
              ))}

              <div className="flex justify-start px-6 pb-6">
                <button
                  type="submit"
                  className="bg-woagreen text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  Save Pricing
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Save Pricing"
        message="Are you sure you want to save the updated pricing? This action cannot be undone."
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
}

export default ManagePricing;
