// src/components/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import Navbar from "../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../Components/Sidebar"; // Adjust this to the actual path
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { refreshAccessToken } from '../utils/API/authApis';

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Dashboard() {
  // States for dynamic data
  const [dashboardStats, setDashboardStats] = useState({
    totalRevenue: 50000,
    dailyRevenue: 1200,
    monthlyRevenue: 15000,
    yearlyRevenue: 120000,
    totalRides: 850,
    totalAccepted: 780,
    totalStarted: 750,
    totalScheduled: 90
  });

  const [latestRides, setLatestRides] = useState([
    {
      name: "John Doe",
      date: "2024-10-07",
      from: "Downtown",
      to: "Airport",
      status: "Completed"
    },
    {
      name: "Jane Smith",
      date: "2024-10-06",
      from: "Central Park",
      to: "Hotel",
      status: "Cancelled"
    },
    {
      name: "Alex Johnson",
      date: "2024-10-05",
      from: "Mall",
      to: "Office",
      status: "In Progress"
    },
    {
      name: "Chris Evans",
      date: "2024-10-04",
      from: "University",
      to: "Home",
      status: "Completed"
    }
  ]);

  const [topDrivers, setTopDrivers] = useState([
    { name: "Mike Thompson", rides: 120 },
    { name: "Sarah Lee", rides: 115 },
    { name: "David Wilson", rides: 100 }
  ]);

  const [topCustomers, setTopCustomers] = useState([
    { name: "Emily Davis", rides: 80 },
    { name: "James Brown", rides: 75 },
    { name: "Robert Garcia", rides: 70 }
  ]);

  const [chartData, setChartData] = useState({
    labels: ["2024-09-01", "2024-09-07", "2024-09-14", "2024-09-21", "2024-09-28", "2024-10-05"],
    datasets: [
      {
        label: 'Rides',
        data: [30, 45, 60, 40, 55, 70],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        pointBorderColor: 'rgba(75, 192, 192, 1)',
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        fill: true,
      },
    ],
  });

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Rides Graph',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Rides',
        },
      },
    },
  };

  useEffect(() => {
    const initiateTokenRefresh = async () => {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        console.log('Access token successfully refreshed upon entering Dashboard');
      } else {
        console.log('Failed to refresh access token upon entering Dashboard');
      }
    };
    initiateTokenRefresh();
  }, []); 

  const fetchDashboardData = async () => {
  };

  useEffect(() => {
    fetchDashboardData();
  }, []); // Fetch data when Dashboard mounts

  return (
    <>
      <Navbar />
      <div className="flex pt-16 h-screen">
        <Sidebar currentSelection={{ main: "dashboard", sub: "null" }} />
        {/* Main content area */}
        <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
          <h1 className="text-xl font-semibold p-6">Dashboard</h1>
          <div className="bg-gray-100 rounded-lg shadow p-6">
            <div className="h-[60vh] flex">
              {/* Left Side - Stats Section */}
              <div className="w-2/5 grid grid-cols-2 gap-4">
                {/* Revenue and Ride Stats */}
                {Object.keys(dashboardStats).map((key, index) => (
                  <div key={index} className="bg-white p-2 rounded-lg shadow-md text-center min-h-[100px] flex flex-col justify-center">
                    <h3 className="text-sm font-medium text-gray-600">{key.replace(/([A-Z])/g, ' $1').trim()}</h3>
                    <p className="text-base font-bold">{dashboardStats[key]}</p>
                  </div>
                ))}
              </div>

              {/* Right Side - Latest Rides Section */}
              <div className="w-4/5 ml-4">
                {/* Ride List */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Latest Rides</h2>
                    <div className="flex space-x-4">
                      <button className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600">
                        Refresh
                      </button>
                      <button className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-600">
                        View All Rides
                      </button>
                    </div>
                  </div>

                  {/* Ride Items */}
                  {latestRides.map((ride, index) => (
                    <div key={index} className="flex items-center py-2 border-b border-gray-200">
                      <div className="w-10 h-10 rounded-full bg-blue-200 flex items-center justify-center">
                        {/* Placeholder for user's avatar */}
                        <span className="text-lg font-bold text-blue-600">👤</span>
                      </div>
                      <div className="ml-4 flex-1">
                        <p className="font-medium">{ride.name}</p>
                        <p className="text-sm text-gray-500">{ride.date}</p>
                        <p className="text-sm">
                          <span className="text-red-500">📍 {ride.from}</span>
                        </p>
                        <p className="text-sm">
                          <span className="text-green-500">📍 {ride.to}</span>
                        </p>
                      </div>
                      <p className="ml-4 text-sm font-medium text-red-500">{ride.status}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* NEW SECTION: Chart and Tables */}
            <div className="bg-white p-6 rounded-lg shadow-md mt-10">
              {/* Chart.js Rides Graph */}
              <Line data={chartData} options={chartOptions} />

              {/* Top Drivers and Customers Tables */}
              <div className="grid grid-cols-2 gap-6 mt-6">
                {/* Top Drivers */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-lg font-bold mb-4">Top Drivers</h2>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="text-left py-2 px-4 border-b">S.no</th>
                        <th className="text-left py-2 px-4 border-b">Driver Name</th>
                        <th className="text-left py-2 px-4 border-b">Total Rides</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topDrivers.map((driver, index) => (
                        <tr key={index}>
                          <td className="py-2 px-4 border-b">{index + 1}</td>
                          <td className="py-2 px-4 border-b">{driver.name}</td>
                          <td className="py-2 px-4 border-b">{driver.rides}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Top Customers */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-lg font-bold mb-4">Top Customers</h2>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="text-left py-2 px-4 border-b">S.no</th>
                        <th className="text-left py-2 px-4 border-b">Customer Name</th>
                        <th className="text-left py-2 px-4 border-b">Total Rides</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topCustomers.map((customer, index) => (
                        <tr key={index}>
                          <td className="py-2 px-4 border-b">{index + 1}</td>
                          <td className="py-2 px-4 border-b">{customer.name}</td>
                          <td className="py-2 px-4 border-b">{customer.rides}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
