// fareApis.js
import {
  ADD_FARE,
  DELETE_FARE,
  EDIT_FARE,
  GET_ALL_FARES,
  BASE_URL,
  ADMIN_ROUTE,
} from "./apiEndPoint"; 
import {
  apiDeleteFetcher,
  apiPostFetcher,
  apiPutFetcher,
  apiGetFetcher,
} from "./apiFetcher";

/**
 * Fetches all fares with pagination using path parameters.
 * @param {number} pageNumber - The page number to retrieve.
 * @param {number} itemsPerPage - The number of items per page.
 * @param {object} queryParams - Additional query parameters.
 * @returns {Promise} - An object containing data, status, and error.
 */
const getAllFares = (pageNumber, itemsPerPage, queryParams = {}) => {
  // Assuming queryParams is used for search or filtering
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BASE_URL}${ADMIN_ROUTE}${GET_ALL_FARES}/${pageNumber}/${itemsPerPage}?${queryString}`;
  return apiGetFetcher(url);  
};

/**
 * Deletes a fare by ID.
 * @param {string} fareID - The ID of the fare to delete.
 * @returns {Promise} - An object containing data, status, and error.
 */
const deleteFareAPI = async (fareID) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${DELETE_FARE}/${fareID}`;
  return apiDeleteFetcher(url);
};

/**
 * Adds a fare using JSON.
 * @param {object} fareData - Details of the fare to be added.
 * @returns {Promise} - An object containing data, status, and error.
 */
const addFareAPI = async (fareData) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${ADD_FARE}`;
  return apiPostFetcher(url, fareData, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

/**
 * Updates a fare's details using JSON.
 * @param {object} fareData - Updated details of the fare.
 * @param {string} fareID - The ID of the fare to edit.
 * @returns {Promise} - An object containing data, status, and error.
 */
const updateFareAPI = async (fareData, fareID) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${EDIT_FARE}/${fareID}`;
  return apiPutFetcher(url, fareData, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    }
  });
};

/**
 * Fetches a fare by its ID.
 * @param {string} fareID - The ID of the fare to fetch.
 * @returns {Promise} - An object containing data, status, and error.
 */
const getFareByIdAPI = async (fareID) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}$GET_FARE_BY_ID}/${fareID}`;
  return apiGetFetcher(url);
};

// Exporting the functions as an object
const fareApiFnc = {
  getAllFares,
  deleteFareAPI,
  addFareAPI,
  updateFareAPI,
  getFareByIdAPI, // Export the new function
};

export default fareApiFnc;
