import React, { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import customerApiFnc from '../../utils/API/customerApis'; // Ensure correct path
import { toast } from 'sonner';

const CustomerSearch = ({ onSearchResults, selectedCustomer, setSelectedCustomer }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Update searchTerm when selectedCustomer changes
  useEffect(() => {
    if (selectedCustomer) {
      const fullName = `${selectedCustomer.firstName} ${selectedCustomer.lastName}`;
      setSearchTerm(fullName);
    } else {
      setSearchTerm('');
    }
  }, [selectedCustomer]);

  // Debounced customer fetch
  const fetchCustomers = useMemo(
    () =>
      debounce(async (term) => {
        if (!term) return;

        console.log('Fetching customers for term:', term);
        setLoading(true);
        setError(null);

        try {
          const pageNumber = 1;
          const itemsPerPage = 1000;
          const payload = {};

          const response = await customerApiFnc.getAllCustomers(pageNumber, itemsPerPage, payload);
          console.log('API Response:', response);

          if (response.status === 200) {
            console.log('Fetched customers:', response.data.customers);

            // Client-side filtering
            const filteredCustomers = response.data.customers.filter((customer) =>
              customer.phoneNumber.includes(term)
            );

            onSearchResults(filteredCustomers);

            if (filteredCustomers.length > 0) {
              toast.success(`Found ${filteredCustomers.length} customer(s) matching "${term}".`);
            } else {
              toast.info(`No customers found matching "${term}".`);
            }
          } else {
            console.error('Error fetching customers:', response.error);
            setError('Failed to fetch customers.');
            onSearchResults([]);
            toast.error('Failed to fetch customers. Please try again.');
          }
        } catch (error) {
          console.error('Error fetching customers:', error);
          setError('An error occurred while fetching customers.');
          onSearchResults([]);
          toast.error('An error occurred while fetching customers.');
        } finally {
          setLoading(false);
        }
      }, 500),
    [onSearchResults]
  );

  // Handle input change
  const handleChange = (e) => {
    const phone = e.target.value.replace(/\D/g, ''); // Remove any non-numeric characters
    setSearchTerm(phone);
    if (phone) {
      fetchCustomers(phone);
      if (selectedCustomer) {
        setSelectedCustomer(null);
      }
    } else {
      onSearchResults([]);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2">Select Customer :</label>
      <div className="flex">
        <input
          type="tel"
          pattern="[0-9]*" // Allows only numeric values
          className="w-full border border-gray-300 rounded-r px-3 py-2"
          placeholder="Enter Mobile Number"
          value={searchTerm}
          onChange={handleChange}
          readOnly={!!selectedCustomer}
        />
      </div>
      {/* Clear Selection Button */}
      {selectedCustomer && (
        <button
          onClick={() => {
            setSelectedCustomer(null);
            onSearchResults([]);
          }}
          className="text-blue-500 underline mt-2"
        >
          Change Customer
        </button>
      )}
      {/* Loading Indicator */}
      {loading && <p className="text-sm text-gray-500 mt-2">Loading...</p>}
      {/* Error Message */}
      {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default CustomerSearch;
