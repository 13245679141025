// src/components/User/AddEditUser.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import usersApi from "../../utils/API/userApis";
import useAuthToken from "../../hooks/customHooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

function AddEditUser() {
  const token = useAuthToken();
  const navigate = useNavigate();
  const { userId } = useParams(); // Assuming you're using route params for editing

  // Determine if the form is in edit mode based on the presence of userId
  const [isEdit, setIsEdit] = useState(false);

  // Initial user details
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "enabled",
    countryCode: "+91",
    phoneNumber: "",
    password: "",
    orgName: "",
    roles: "",
  });

  // Handle input changes with validation
  const handleChange = (e) => {
    const { name, value } = e.target;

    // For First Name and Last Name, allow only alphabetic characters
    if (name === "firstName" || name === "lastName") {
      const regex = /^[A-Za-z\s'-]*$/;
      if (!regex.test(value)) {
        toast.error("Only alphabetic characters are allowed.");
        return;
      }
    }

    // For phoneNumber, allow only digits
    if (name === "phoneNumber") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        toast.error("Only numeric characters are allowed.");
        return;
      }
    }

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Fetch user data if in edit mode
  useEffect(() => {
    if (userId) {
      setIsEdit(true);
      // Fetch user details from API
      const fetchUserDetails = async () => {
        try {
          const response = await usersApi.getUserAPI(userId, token);
          if (response.error) {
            toast.error(response.data.msg || "Something went wrong");
            navigate("/users");
          } else {
            const data = response.data;
            setUserDetails({
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              email: data.email || "",
              status: data.status || "enabled",
              countryCode: data.countryCode || "+91",
              phoneNumber: data.phoneNumber || "",
              password: "", // Do not pre-populate password
              orgName: data.orgName || "",
              roles: data.roles || "",
            });
          }
        } catch (err) {
          toast.error("Failed to fetch user details.");
          navigate("/users");
        }
      };

      fetchUserDetails();
    }
  }, [userId, token, navigate]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the payload
    const payload = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      countryCode: userDetails.countryCode,
      phoneNumber: userDetails.phoneNumber,
      orgName: userDetails.orgName,
      roles: userDetails.roles,
      status: userDetails.status,
    };

    // Only include password if it's not empty (i.e., when creating a new user or changing the password)
    if (userDetails.password) {
      payload.password = userDetails.password;
    }

    let response;
    try {
      if (isEdit) {
        response = await usersApi.updateUserAPI(userId, payload, token);
      } else {
        response = await usersApi.addUserAPI(payload, token);
      }

      // Handle API response
      if (response.error) {
        // Handle error (e.g., display error message)
        toast.error(response.data.msg || "Something went wrong");
      } else {
        // Handle success (e.g., redirect, display success message)
        toast.success(
          isEdit ? "User updated successfully!" : "User added successfully!"
        );
        // Redirect to users list
        navigate("/users");
        // Reset form if adding a new user
        if (!isEdit) {
          setUserDetails({
            firstName: "",
            lastName: "",
            email: "",
            status: "enabled",
            countryCode: "+91",
            phoneNumber: "",
            password: "",
            orgName: "",
            roles: "",
          });
        }
      }
    } catch (err) {
      toast.error("An unexpected error occurred.");
    }
  };

  // Handle form reset
  const handleReset = () => {
    if (isEdit) {
      // Reset to original user details fetched from API
      // You may need to store the original details in another state
      // For simplicity, we'll navigate back
      navigate("/users");
    } else {
      // Reset form fields
      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        status: "enabled",
        countryCode: "+91",
        phoneNumber: "",
        password: "",
        orgName: "",
        roles: "",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <div className="flex justify-between">
                <div className="text-xl py-2 my-2">
                  {isEdit ? `Edit User` : `Add User`}
                </div>
                <div>
                  <button
                    onClick={() => navigate("/users")}
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    Back
                  </button>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-6 mt-4">
                  {/* First Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      required
                      maxLength={50}
                      pattern="[A-Za-z\s'-]+"
                      title="First name should contain only letters, spaces, apostrophes, or hyphens."
                    />
                  </div>

                  {/* Last Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter last name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      required
                      maxLength={50}
                      pattern="[A-Za-z\s'-]+"
                      title="Last name should contain only letters, spaces, apostrophes, or hyphens."
                    />
                  </div>

                  {/* Email */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter email address"
                      value={userDetails.email}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      required
                      maxLength={100}
                      // Prevent auto-population in Add mode
                      autoComplete={isEdit ? "email" : "new-password"}
                    />
                  </div>

                  {/* Grouped Country Code and Phone Number */}
                  <div className="flex flex-col text-gray-600 col-span-3">
                    <label htmlFor="phoneNumber">Phone Number *</label>
                    <div className="flex space-x-4 mt-1">
                      {/* Country Code Dropdown */}
                      <select
                        id="countryCode"
                        name="countryCode"
                        value={userDetails.countryCode}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-xl p-2 w-1/4"
                        required
                        maxLength={5}
                      >
                        <option value="+91">India (+91)</option>
                        <option value="+1">United States (+1)</option>
                        <option value="+44">United Kingdom (+44)</option>
                        <option value="+61">Australia (+61)</option>
                        <option value="+971">UAE (+971)</option>
                        {/* Add more country options as needed */}
                      </select>

                      {/* Phone Number */}
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        value={userDetails.phoneNumber}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-xl p-2 w-3/4"
                        required
                        maxLength={15}
                        pattern="[0-9]{7,15}"
                        title="Phone number should contain between 7 and 15 digits."
                      />
                    </div>
                  </div>

                  {/* Password */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="password">
                      Password {isEdit ? "" : "*"}
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder={
                        isEdit
                          ? "Leave blank to keep current password"
                          : "Enter password"
                      }
                      value={userDetails.password}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      {...(!isEdit && { required: true })}
                      maxLength={50}
                      autoComplete="new-password"
                    />
                  </div>

                  {/* Organization Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="orgName">Organization Name *</label>
                    <input
                      type="text"
                      id="orgName"
                      name="orgName"
                      placeholder="Enter organization name"
                      value={userDetails.orgName}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      required
                      maxLength={100}
                    />
                  </div>

                  {/* Roles */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="roles">Roles *</label>
                    <select
                      id="roles"
                      name="roles"
                      value={userDetails.roles}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                      required
                      maxLength={50}
                    >
                      <option value="" disabled>
                        Select Role
                      </option>
                      <option value="SUPERADMIN">SUPERADMIN</option>
                      <option value="ADMIN">ADMIN</option>
                      <option value="ACCOUNTANT">ACCOUNTANT</option>
                      {/* Add more roles as needed */}
                    </select>
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    {isEdit ? "Update" : "Save"}
                  </button>
                  <button
                    type="button"
                    onClick={handleReset}
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditUser;
