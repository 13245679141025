// src/Pages/Vehicles/FareTableList.jsx

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import fareApiFnc from "../../utils/API/fareApis";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; // Import Sonner for toast notifications
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function FareTableList() {
  const navigate = useNavigate();
  const token = useAuthToken();

  const [fares, setFares] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalFares, setTotalFares] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fareToDelete, setFareToDelete] = useState(null);

  /**
   * Fetches fares from the API based on current filters and pagination.
   */
  const fetchFares = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fareApiFnc.getAllFares(currentPage, itemsPerPage, { search: searchQuery }, token);
      setFares(data.data.paginatedData || []);
      setTotalFares(data.data.totalItems || 0);
      setTotalPages(data.data.totalPages || 1);
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || "Failed to fetch fares.";
      setError(errorMessage);
      toast.error("Failed to fetch fares. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchQuery]);

  /**
   * Handles the addition of a new fare.
   */
  const handleAddFare = () => {
    navigate("/vehicles/fares/add");
  };

  /**
   * Handles the editing of a fare.
   * @param {object} fare - The fare object to edit.
   */
  const handleEditFare = (fare) => {
    navigate(`/vehicles/fares/edit/${fare.id}`, { state: { fare } });
  };

  /**
   * Opens the confirmation modal to delete a fare.
   * @param {object} fare - The fare object to delete.
   */
  const handleDeleteClick = (fare) => {
    setFareToDelete(fare);
    setIsModalOpen(true);
  };

  /**
   * Confirms the deletion of the fare.
   */
  const confirmDeleteFare = async () => {
    if (!fareToDelete) return;

    setIsModalOpen(false);
    setLoading(true);
    setError(null);

    try {
      await fareApiFnc.deleteFareAPI(fareToDelete.id, token);
      toast.success("Fare deleted successfully!");
      // Refetch fares to update the list
      fetchFares();
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || "Failed to delete fare.";
      setError(errorMessage);
      toast.error("Failed to delete fare. Please try again.");
    } finally {
      setLoading(false);
      setFareToDelete(null);
    }
  };

  /**
   * Cancels the deletion and closes the modal.
   */
  const cancelDelete = () => {
    setIsModalOpen(false);
    setFareToDelete(null);
  };

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "managePricing", sub: null }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <h1 className="text-2xl text-gray-600 font-bold mb-4">Pricing Table</h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search by Vehicle Category</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Enter vehicle category"
                    className="border border-gray-300 rounded p-2 w-96"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Fares List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Pricing List</div>
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">Items per page:</label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  onClick={handleAddFare}
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                >
                  + Add New Fare
                </button>
              </div>
            </div>

            {/* Pricing Table */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Vehicle Category</div>
                <div className="flex-1 text-left">Rate Per Km</div>
                <div className="flex-1 text-left">Rate Per Hour</div>
                <div className="flex-1 text-left">Rate Per Day</div>
                <div className="flex-1 text-left">WOA Price %</div>
                <div className="flex-1 text-left">Base Fare</div>
                <div className="flex-1 text-left">Minimum Fare</div>
                <div className="flex-1 text-left">Minimum Km</div>
                <div className="flex-1 text-left">Special Charge %</div>
                <div className="flex-1 text-right">Actions</div>
              </div>
              {loading ? (
                <div className="flex justify-center items-center p-4">Loading fares...</div>
              ) : error ? (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  {error}
                </div>
              ) : fares.length > 0 ? (
                fares.map((fare) => (
                  <div
                    key={fare.id} // Ensure fare.id is unique and exists
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{fare.id}</div>
                    <div className="flex-1 text-left">{fare.vehicleCategory}</div>
                    <div className="flex-1 text-left">{fare.ratePerKm}</div>
                    <div className="flex-1 text-left">{fare.ratePerHour}</div>
                    <div className="flex-1 text-left">{fare.ratePerDay}</div>
                    <div className="flex-1 text-left">{fare.woa_price_percentage}</div>
                    <div className="flex-1 text-left">{fare.base_fare}</div>
                    <div className="flex-1 text-left">{fare.minimum_fare}</div>
                    <div className="flex-1 text-left">{fare.minimum_km}</div>
                    <div className="flex-1 text-left">{fare.specialcharge_percentage}</div>
                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded p-2 text-xs h-8 hover:bg-green-100"
                        onClick={() => handleEditFare(fare)}
                        aria-label={`Edit fare ${fare.id}`}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded p-2 text-xs h-8 hover:bg-red-100"
                        onClick={() => handleDeleteClick(fare)}
                        aria-label={`Delete fare ${fare.id}`}
                        disabled={loading} // Disable while loading
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">No fares found.</div>
              )}
            </div>

            {/* Pagination Component */}
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}

            {/* Footer (Optional) */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-6 rounded-b-xl">
              <div></div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this fare? This action cannot be undone.`}
        onConfirm={confirmDeleteFare}
        onCancel={cancelDelete}
      />

      {/* Remove the fixed error display and rely on toasts */}
      {/* {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )} */}
    </>
  );
}

export default FareTableList;
